import React, { useState, useEffect } from 'react';
import styles from './PayForm.module.scss';
import BanknoteIcon from '../../../../img/imageComponents/BanknoteIcon';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../../../http';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import convertTime from '../../../../helpers/convertTime';
import { useTranslation } from "react-i18next";


const PayForm = ({type, selectedGroup, batch, laboratory, dateAnalysis, expDate, productName}) => {
  const [value, setValue] = useState('1 certificate credit');
  const [total, setTotal] = useState(0);
  // const [isAllowSubmit, setIsAllowSubmit] = useState(false);
  const {id} = useParams();
  const { t } = useTranslation();

  const sendCertificate = (e) => {
    const data = { 
      analysisCertificateType: type,
      productGroup: selectedGroup?.id,
      productName: productName,
      batchNumber: batch,
      laboratoryAddress: laboratory,
      analysisDate: convertTime(dateAnalysis),
      expirationDate: convertTime(expDate)
    }
    axios({
      method: 'post',
      url: `${API_URL}/certificate`,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      data
    })
    .then(data => {
      // setErrorForm("");
      // setCertificateId(data?.data?.id)
      console.log(data)
      setTotal(prev => prev - 1);
      navigate(`/thank/${data.data.id}`)
    })
    .catch(e => {
      console.log(e)
      // switch(e?.response?.data?.statusCode){
      //   case 401: {
      //     return setErrorForm("Try to re login")
      //   }
      //   case 400: {
      //     return setErrorForm("Check the validity of the filled fields")
      //   }
      //   case 404: {
      //     return setErrorForm("Product group with given name doesn't exist")
      //   }
      //   default: {
      //     return setErrorForm("Something went wrong")
      //   }
      // }
    })
  }

  const navigate = useNavigate();

  const buyCertificate = () => {
    setTotal(prev => prev - 1);
  }

  useEffect(() => {
    // if(total < 230){
    //   setTimeout(() => {
    //     navigate(`/thank/${id}`)
    //   }, 500)
    // }
  }, [total])

  useEffect(() => {
    axios({
      method: "get",
      url: `${API_URL}/user/me`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      setTotal(response.data.certificatesBalance)
    })
    .catch((e) => {
      console.log(e);
    });
  }, [])
  

  return (
    <div className={styles.form}>
      <div className={styles.form__label}>
        {t("To pay")}
      </div>
      <div className={styles.form__wrapper}>
        <input 
          type="text" 
          value={value}
          readOnly
          onChange={(e) => setValue(e.target.value)}
          className={styles.form__input}
        />
        <div className={`${styles.form__balance} ${styles.form__balance_mobile}`}>
          {t("Your balance:")} <span>{total} {t("certificate credits")}</span>
        </div>
        <button 
          className={cn(styles.form__button, total == 0 ? styles.form__button_disable : "")} 
          onClick={() => sendCertificate()}
        >
          <BanknoteIcon />
          <div>
            {t("Pay now")}
          </div>
        </button>
      </div>
      <div className={styles.form__balance}>
        {t("Your balance:")} <span>{total} {t("certificate credits")}</span>
      </div>
    </div>
  )
}

export default PayForm