import axios from "axios";
import {makeAutoObservable} from "mobx";
import AuthService from "../services/AuthService";
import { API_URL } from "../http";

export default class Store {

  isAuth = false;

  userId;

  registred = false;

  errorRegistraion = [];

  showPageCheckPost = false;
  loginError = [];
  showMainPage = false;
  isLoadSignUp = false;


  constructor() {
    makeAutoObservable(this);
  }

  setAuth(bool){
    this.isAuth = bool;
  }

  setUserId(id){
    this.userId(id)
  } 

  async verifyUser(id){
    try{
      const response = await AuthService.verifyUser(id);
      this.userId = id;
      localStorage.setItem('token', response.data.accessToken);
      localStorage.setItem('tokenRefresh', response.data.refreshToken);
      this.setAuth(true);
      this.showPageCheckPost = false;
      this.showMainPage = false;
    } catch(e) {
      this.showPageCheckPost = false;
      this.showMainPage = false;
    }
  }

  // done
  async login(email, password){
    try{
      const response = await AuthService.login(email, password);
      localStorage.setItem('token', response.data.accessToken);
      localStorage.setItem('tokenRefresh', response.data.refreshToken);
      this.setAuth(true);
      this.showPageCheckPost = false;
      this.showMainPage = false;
    } catch(e) {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenRefresh');
      this.setAuth(false);
      this.showPageCheckPost = false;
      this.showMainPage = false;
      if(e.response.status === 400){
        this.loginError = []
        this.loginError = [...this.loginError, ...e.response.data.message]
      }
      if(e.response.status === 401){
        this.loginError = []
        this.loginError.push(e.response.data.message)
      }
    }
  }


  async registration(username, email, password){
    try{
      const response = await AuthService.registration(username, email, password);
      localStorage.setItem('token', response.data.accessToken);
      this.setAuth(true);
    } catch(e) {
      console.log(email, password)
    }
  }

  // done
  async signup(username, email, password){
    this.isLoadSignUp = true;
    try{
      const response = await AuthService.signup(username, email, password);
      this.registred = true;
      this.errorRegistraion = [];
      this.acceptNavigate = true;
      this.showPageCheckPost = true;
      this.showMainPage = false;
      this.isLoadSignUp = false;
      localStorage.removeItem('token');
      localStorage.removeItem('tokenRefresh');
      this.setAuth(false);
    } catch(e) {
      this.showPageCheckPost = false;
      this.showMainPage = false;
      this.isLoadSignUp = false;
      this.setAuth(false);
      if(e.response.data.error){
        this.errorRegistraion = [];
        this.errorRegistraion = [...this.errorRegistraion, e.response.data.message]
      }
      if(e.response.status == 422){
        this.errorRegistraion = [];
        this.errorRegistraion.push(e.response.data.message)
      }

    }
  }

  async logout(){
    try{
      const response = await AuthService.logout();
      localStorage.removeItem('token');
      localStorage.removeItem('tokenRefresh');
      this.setAuth(false);
      this.showPageCheckPost = false;
      this.showMainPage = true;
    } catch(e) {
      this.showPageCheckPost = false;
      this.showMainPage = false;
    }
  }


  async checkAuth() {
    try {
      // const response = await axios.get(`${API_URL}/auth/refresh`, {withCredentials: true});
      const response = await axios({
        method: 'get',
        url: `${API_URL}/auth/refresh`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('tokenRefresh')}`,
          'Content-Type': 'application/json'
        }
      })
      localStorage.setItem('token', response.data.accessToken);
      localStorage.setItem('tokenRefresh', response.data.refreshToken);
      this.setAuth(true);
      this.setUserId(response.data.user)
      this.showPageCheckPost = false;
      this.showMainPage = false;
    } catch (e) {
      this.showPageCheckPost = false;
      this.showMainPage = false;
    }
  }

}