import React, { useState, useEffect } from 'react'
import styles from './ContactUs.module.scss'
import '../../common.css'
import Letter from '../../img/imageComponents/Letter'
import Phone from '../../img/imageComponents/PhoneIcon'
import PointIcon from '../../img/imageComponents/PointIcon'
import Input from '../../Components/Input/Input';
import ArrowIcon from '../../img/imageComponents/ArrowIcon';
import { useTranslation } from 'react-i18next';
import checkIcon from '../../img/checkCircle.svg';
import cn from 'classnames'

const ContactUs = () => {
  const { t } = useTranslation(); 
  const [isModal, setIsModal] = useState(false);
  const [isAllowSubmit, setIsAllowSubmit] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');


  useEffect(() => {
    if(
      name.length &&
      email.length &&
      subject.length &&
      message.length
    ){
      setIsAllowSubmit(true);
    }else{
      setIsAllowSubmit(false);
    }
  }, [name, email, subject, message]) 

  return (
    <section className={styles.contact}>
      <div className="container">
        <h2 className={styles.contact__title}>{t("Contact us")}</h2>
        <div className={styles.contact__wrapper}>
          <div className={styles.contact__info}>
            <div className={styles.contact__info_row}>
              <Letter />
              <div className={styles.contact__info_row_block}>
                <div className={styles.contact__info_label}>Email</div>
                <a className={styles.contact__info_value} href="mailto:info@Lab-Authenticity.org">info@Lab-Authenticity.org</a>
              </div>
            </div>
            {/* <div className={styles.contact__info_row}>
              <Phone />
              <div className={styles.contact__info_row_block}>
                <div className={styles.contact__info_label}>Phone</div>
                <a className={styles.contact__info_value} href="tel: 123-456-7890">+1 000 000 00 00</a>
              </div>
            </div> */}
            <div className={styles.contact__info_row}>
              <PointIcon />
              <div className={styles.contact__info_row_block}>
                <div className={styles.contact__info_label}>{t("Office Address")}</div>
                <div className={styles.contact__info_value}>
                  CeBiol Blockchain Solutions GmbH <br/>
                  Peutestrasse 53C <br/>
                  20539 Hamburg, Germany <br/>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.contact__form}>
            <div className={styles.contact__form_title}>{t("Leave A Message For Us")}</div>
            <Input
              placeholder={t("Enter your name")}
              label={t("Your Name")}
              value={name}
              onChange={setName}
            />
            <Input
              placeholder={t("Enter Email")}
              label={t("Enter Email")}
              value={email}
              onChange={setEmail}
            />
            <Input
              placeholder={t("Enter Subject")}
              label={t("Enter Subject")}
              value={subject}
              onChange={setSubject}
            />
            <label className={styles.contact__text}>
              <div>{t("Message")}</div>
              <textarea 
                placeholder={t("Message")}
                value={message}
                onChange={(e) => {setMessage(e.target.value)}}
              >
              </textarea>
            </label>
            <button 
              className={cn(styles.contact__button, !isAllowSubmit ? styles.contact__button_disabled : '')}
              onClick={() => setIsModal(true)}
            >
              <div>{t("Send")}</div>
              <ArrowIcon color="#ffffff" />
            </button>
          </div>
        </div>
      </div>
      {
        isModal ?
        <div className={styles.contact__modal_wrapper}>
          <div className={styles.contact__modal}>
            <div className={styles.contact__modal_title}>{t("Message sent")}</div> 
            <img className={styles.contact__modal_image} src={checkIcon} alt="check" />
            <button onClick={() => setIsModal(prev => !prev)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 5L5 15" stroke="#0091D2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5 5L15 15" stroke="#0091D2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
        :
        null
      }
    </section>
  )
}

export default ContactUs;