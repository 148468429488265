import React, { useEffect, useState } from "react";
import styles from "./Certificate.module.scss";
import Status from "../../../../Components/Status/Status";
import DownloadIcon from "../../../../img/imageComponents/DownloadIcon";
import Document from "../../../../img/imageComponents/Document";
import EditIcon from "../../../../img/imageComponents/EditIcon";
import IconLink from "../../../../img/imageComponents/IconLink";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL } from "../../../../http";

// const tableData = [
//   {type: "Lorem ipsum Certifiacte", status: "In Process", category: "Consectetur adipiscing elit /Sed do eiusmod", date: "Oct 21, 11:57 AM", transactionId: "6f3fd01aw2je3jeh3bej3heej3e6f3fd01aw2je3jeh3bej3heej3e", id: 1,},
//   {type: "Lorem ipsum Certifiacte", status: "Live", category: "Consectetur adipiscing elit /Sed do eiusmod", date: "Oct 21, 11:57 AM", transactionId: "6f3fd01aw2je3jeh3bej3heej3e6f3fd01aw2je3jeh3bej3heej3e", id: 2,},
//   {type: "Lorem ipsum Certifiacte", status: "Live", category: "Consectetur adipiscing elit /Sed do eiusmod", date: "Oct 21, 11:57 AM", transactionId: "6f3fd01aw2je3jeh3bej3heej3e6f3fd01aw2je3jeh3bej3heej3e", id: 3,},
//   {type: "Lorem ipsum Certifiacte", status: "Live", category: "Consectetur adipiscing elit /Sed do eiusmod", date: "Oct 21, 11:57 AM", transactionId: "6f3fd01aw2je3jeh3bej3heej3e6f3fd01aw2je3jeh3bej3heej3e", id: 4,},
//   {type: "Lorem ipsum Certifiacte", status: "Live", category: "Consectetur adipiscing elit /Sed do eiusmod", date: "Oct 21, 11:57 AM", transactionId: "6f3fd01aw2je3jeh3bej3heej3e6f3fd01aw2je3jeh3bej3heej3e", id: 5,},
//   {type: "Lorem ipsum Certifiacte", status: "Cancelled", category: "Consectetur adipiscing elit /Sed do eiusmod", date: "Oct 21, 11:57 AM", transactionId: "6f3fd01aw2je3jeh3bej3heej3e6f3fd01aw2je3jeh3bej3heej3e", id: 6,},
//   {type: "Lorem ipsum Certifiacte", status: "Cancelled",category: "Consectetur adipiscing elit /Sed do eiusmod", date: "Oct 21, 11:57 AM", transactionId: "6f3fd01aw2je3jeh3bej3heej3e6f3fd01aw2je3jeh3bej3heej3e", id: 7,}
// ];

const Certificate = () => {
  const { t } = useTranslation();
  const [certificatesList, setCertificatesList] = useState();

  useEffect(() => {
    axios({
      method: "get",
      url: `${API_URL}/certificate/user/certificates`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        setCertificatesList(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log(certificatesList);

  return (
    <section className={styles.certificate}>
      <h2 className={styles.certificate__title}>
        {t("Certificates of Analysis")}
      </h2>
      <div className={styles.certificate__wrapper}>
        <div className={styles.certificate__block}>
          <div className={styles.certificate__block_row}>
            <div className={styles.certificate__block_left}>
              <Status text={t("In Process")} />
            </div>
            <div className={styles.certificate__block_text}>
              {t(
                "Your order is being processed - please send your lab the transaction ID of this order and ask them to send us the certificate of analysis."
              )}
            </div>
          </div>
          <div className={styles.certificate__block_row}>
            <div className={styles.certificate__block_left}>
              <Status text={t("Live")} />
            </div>
            <div className={styles.certificate__block_text}>
              {t(
                "Your analysis certificate has been added to the blockchain and is available for retrieval."
              )}
            </div>
          </div>
          <div className={styles.certificate__block_row}>
            <div className={styles.certificate__block_left}>
              <Status text={t("Cancelled")} />
            </div>
            <div className={styles.certificate__block_text}>
              {t("Your order has been cancelled.")}
            </div>
          </div>
        </div>
        <Link
          to={"/certificates/new"}
          className={styles.certificate__addCertificate}
        >
          <DownloadIcon color="#ffffff" />
          <div>{t("Add Certificate")}</div>
        </Link>
      </div>
      {certificatesList && certificatesList.length ? (
        <div className={styles.certificate__table}>
          <div className={styles.certificate__table_header}>
            <div
              className={`${styles.certificate__table_title} ${styles.certificate__type}`}
            >
              {t("type")}
            </div>
            <div
              className={`${styles.certificate__table_title} ${styles.certificate__status}`}
            >
              {t("Status")}
            </div>
            <div
              className={`${styles.certificate__table_title} ${styles.certificate__name}`}
            >
              {t("Caterory / Name")}
            </div>
            <div
              className={`${styles.certificate__table_title} ${styles.certificate__date}`}
            >
              {t("date")}
            </div>
            <div
              className={`${styles.certificate__table_title} ${styles.certificate__id}`}
            >
              {t("Blockchain transaction ID")}
            </div>
            <div
              className={`${styles.certificate__table_title} ${styles.certificate__action}`}
            >
              {t("Action")}
            </div>
          </div>
          {certificatesList?.map((item) => {
            const { analysisCertificateType, status, productGroup, expirationDate, id, pdfLink} = item;
            return (
              <div className={styles.certificate__table_row} key={id}>
                <div
                  className={`${styles.certificate__table_value} ${styles.certificate__type}`}
                >
                  <Document />
                  <div>{analysisCertificateType}</div>
                </div>
                <div
                  className={`${styles.certificate__table_value} ${styles.certificate__status}`}
                >
                  <Status text={t(status)} />
                </div>
                <div
                  className={`${styles.certificate__table_value} ${styles.certificate__name}`}
                >
                  {productGroup}
                </div>
                <div
                  className={`${styles.certificate__table_value} ${styles.certificate__date}`}
                >
                  {expirationDate}
                </div>
                <div
                  className={`${styles.certificate__table_value} ${styles.certificate__id} ${styles.certificate__id_value}`}
                >
                  {pdfLink}
                </div>
                <div
                  className={`${styles.certificate__table_value} ${styles.certificate__action}`}
                >
                  <button>
                    <EditIcon width="24px" height="24px" />
                  </button>
                  <a href="/">
                    <DownloadIcon />
                  </a>
                  <a href="/">
                    <IconLink />
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.certificate__empty}>
          <div>{t("You have no certificates")} 🙅‍♂️</div>
        </div>
      )}
    </section>
  );
};

export default Certificate;
