import React, { useState } from 'react'
import styles from './PaymentModal.module.scss'
import CloseIcon from '../../../../img/imageComponents/CloseIcon'
import Timer from '../Timer/Timer'
import SettingsContext from '../SettingsContext/SettingsContext'
import QRCode from 'react-qr-code'

const PaymentModal = ({ closeAction }) => {
  const [paymentStep, setPaymentStep] = useState(1);
  const [workMinutes, setWorkMinutes] = useState(10);
  const [breakMinutes, setBreakMinutes] = useState(0);

  return (
    <div className={styles.modal}>
      <div className={styles.modal__inner}>
        <button 
          className={styles.modal__close}
          onClick={() => closeAction(false)}
        >
          <CloseIcon />
        </button>
        <div className={styles.modal__title}>
          Pay with CBSL
        </div>
        {
          paymentStep === 1 && 
          <>
            <div className={styles.modal__qr}>
              <QRCode 
                value='https://www.lipsum.com/'
              />
            </div>
            <div className={styles.modal__noty}>Scan the QR code and send XXX Tokens to the wallet below (BNB Blockchain)</div>
            <div className={styles.modal__check}>
              To pay: XXX Tokens
            </div>
            <button
              className={styles.modal__paid}
              onClick={() => setPaymentStep(2)}
            >
              I’ve Paid!
            </button>
          </>
        }
        {
          paymentStep === 2 && 
          <>
            <SettingsContext.Provider value={{
              workMinutes,
              breakMinutes,
              setWorkMinutes,
              setBreakMinutes
            }}>
            <Timer />
            </SettingsContext.Provider>
            <div className={styles.modal__noty}>
              Thank you! We are checking the blockchain to see this transaction. It usually takes 5-10 minutes. Please stand by!
            </div>
            <button 
              className={styles.modal__exit}
              onClick={() => closeAction(false)}
            >
              Close
            </button>  
          </>
        }
      </div>
    </div>
  )
}

export default PaymentModal;