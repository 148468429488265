import React, { useEffect, useState } from "react";
import styles from "./Thank.module.scss";
import "../../common.css";
import Back from "../../Components/Buttons/Back/Back";
import WarningCircle from '../../img/imageComponents/WarningCircle';
import thank from '../../img/thank.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Thank = () => {
  const {id} = useParams();
  const [idCertificate, idIdCertificate] = useState(id);
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section className={styles.thank}>
      <div className="container">
        {/* <Back path={"/"} /> */}
        <div className={styles.thank__wrapper}>
          <div className={styles.thank__left}>
            <h2 className={styles.thank__title}>
              {t("Thank You for Your Order!")}
            </h2>
            <div className={styles.thank__id}>
              {t("Your order ID is:")} {idCertificate}
            </div>
            <div className={styles.thank__noty}>
              <WarningCircle color="#EA5455"/>
              <div>{t("Important: Your order is not yet completed!")}</div>
            </div>
            <div className={styles.thank__steps}>
              <div className={styles.thank__steps_title}>
                {t("The following steps are required:")}
              </div>
              <div className={styles.thank__steps_list}>
                <div>{t("1. Please contact now the laboratory that issued the certificate of analysis")}</div><br /><br />
                <div>{t("2. Ask the laboratory to send us the document of the laboratory analysis as a PDF by e-mail to")} <a href={`mailto:cert${idCertificate}@lab-authenticity.org`}>cert{idCertificate}@lab-authenticity.org </a></div><br /><br />
                <div>{t("3. The e-mail must contain the original analysis document and your order ID so that we can assign it to your order.")}</div><br /><br />
                <div>{t("4. As soon as we receive the analysis, the order will be executed and the status of the order will be updated.")}</div>
              </div>
              <div className={styles.thank__steps_noty}>
                {t("These steps are necessary to ensure that only the original analyses are stored in the blockchain.")}
              </div>
            </div>
          </div>
          <div className={styles.thank__right}>
            <img data-aos="fade-up" data-aos-duration="1000" src={thank} alt="thank" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Thank;
