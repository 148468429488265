import React from 'react'
import styles from "./DatePicker.module.scss"
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import CalendarIcon from "../../img/imageComponents/CalendarIcon";
import { useTranslation } from "react-i18next";


const DatePicker = ({ value, datePickAction, placeholder, title }) => {
  const { t } = useTranslation();
  return (
    <label className={styles.date}>
      <span className={styles.span}>
        {t(title)}
      </span>
      <div className={styles.calendarIcon}>
        <CalendarIcon />
      </div>
      <Flatpickr
          value={value}
          onChange={(date) => {
            datePickAction(new Date(date).getTime());
          }}
          className={styles.datePicker}
          placeholder={placeholder}
        />
    </label>
  )
}

export default DatePicker;