import { useEffect } from 'react';
import styles from './How.module.scss';
import '../../../../common.css';
import "animate.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

import step1 from '../../../../img/step1.svg';
import step2 from '../../../../img/step2.svg';
import step3 from '../../../../img/step3.svg';
import step4 from '../../../../img/step4.svg';

import way1 from '../../../../img/way1.svg';
import way2 from '../../../../img/way2.svg';
import way3 from '../../../../img/way3.svg';

const steps = [
  {icon: step1, description : 'Producer (manufacturer of the product) orders laboratory to perform analysis.', id: 0},
  {icon: step2, description : 'Laboratory analyzes the product and issues a certificate of analysis.', id: 1},
  {icon: step3, description : 'The laboratory is instructed by the producer to send the original document to us, in encrypted form.', id: 2},
  {icon: step4, description : 'The certificate of analysis is automatically captured by our system and stored in the blockchain.', id: 3}
]

const How = () => {
  const { t } = useTranslation(); 
  
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <section className={styles.how}>
      <div className='container'>
        <div className={styles.how__content}>
          <div className={styles.how__title}>
            {t("How")}
             <br />
            {t("Lab-Authenticity")}
             <br />
            <span>{t("works")}</span>
          </div>
          <div className={styles.how__steps}>   
            <div className={styles.howStep}>
              <img src={step1} alt='icon' />
              <div className={styles.howStep__descr}>
                {t(steps[0].description)}
              </div>
              <img className={styles.howStep__way1} src={way1} alt='way1' data-aos="fade-right" data-aos-duration="1000"/>
            </div>
            <div className={styles.howStep}>
              <img src={step2} alt='icon' />
              <div className={styles.howStep__descr}>
                {t(steps[1].description)}
              </div>
              <img className={styles.howStep__way2} src={way2} alt='way2' data-aos="fade-down" data-aos-duration="1000"/>
            </div>
            <div className={styles.howStep}>
              <img src={step3} alt='icon' />
              <div className={styles.howStep__descr}>
                {t(steps[2].description)}
              </div>
              <img className={styles.howStep__way3} src={way3} alt='way3' data-aos="fade-right" data-aos-duration="1000"/>
            </div>
            <div className={styles.howStep}>
              <img src={step4} alt='icon' />
              <div className={styles.howStep__descr}>
                {t(steps[3].description)}
              </div>
            </div>
          </div>  
        </div>
      </div>
    </section>
  )
}

export default How;