import React, { useState } from 'react'
import styles from './FaqElement.module.scss'
import DropdownArrow from '../../../img/imageComponents/DropdownArrow'
import { useTranslation } from 'react-i18next';

const FaqElement = ({ title, descr, list = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(); 

  return (
    <div
      onClick={() => setIsOpen(value => !value)}
      className={`${styles.dropdown} ${isOpen ? styles.dropdown__active : ''}`}
    >
      <div className={styles.dropdown__header}>
        <h5 className={styles.dropdown__title}>{title}</h5>
        <DropdownArrow color={`${isOpen ? '#0091D2' : '#2E2E2E'}`}/>
      </div>
      <div className={styles.dropdown__descr}>
        {t(descr)}
      </div>
    </div>
  )
}

export default FaqElement;