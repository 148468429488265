import React from 'react'
import ReactDOM from 'react-dom'
import styles from './Modal.module.scss'
import { Link } from 'react-router-dom';

export const Modal = ({ email }) => {
  return (
    ReactDOM.createPortal(
      <div className={styles.modal}>
        <div className={styles.modal__inner}>
          <div className={styles.modal__title}>
            Verification link was sent to your email address
          </div>
          <div className={styles.modal__descr}>
            Please check your mailbox and follow the link to
            <div>{email}</div>
          </div>
          <Link
            className={styles.modal__back} 
            to={'/'}
          >
            <div>To main page</div>
          </Link>
        </div>
      </div>,
      document.getElementById('portal')
    )
  )
}
