import $api from "../http";
// import {AxiosResponse}  from 'axios'
// import { AuthResponse } from "../models/response/AuthResponse";
import axios from 'axios';

export default class AuthService {


  static async verifyUser(id) { 
    return $api.get(`/auth/verify/${id}`)
  }


  static async login(email, password) {
    return $api.post('/auth/login', {email, password})
  }

  // static async registration(username, email, password){
  //   return $api.post('/auth/signup', {username, email, password}, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Accept": "application/json"
  //     }
  //   })
  // }

  static async signup(username, email, password){
    return $api.post('/auth/signup', {username, email, password}, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
  }

  static async logout() {
    return $api.get('/auth/logout')
  }

}