import React, { useContext, useEffect, useState } from 'react'
import styles from './Login.module.scss'
import '../../common.css';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../../Components/Input/Input';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Context } from '../../index';
import { useObserver, observer } from 'mobx-react-lite';
import cn from 'classnames';
import checkPassword from '../../helpers/checkPassword';
import { regEmail } from '../../helpers/regexp';
import { useTranslation } from 'react-i18next';


const Login = () => {
  const [name, setName] = useState(null);
  const [errorName, setErrorName] = useState('');
  const [pass, setPass] = useState(null);
  const [errorPass, setErrorPass] = useState('');
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation(); 

  const {store} = useContext(Context);
  const navigate = useNavigate();

  const { isAuth, loginError } = useObserver(() => ({
    isAuth: store.isAuth,
    loginError: store.loginError
  }));

  useEffect(() => {
    if(loginError.length){
      setErrors(loginError)
    }else{
      setErrors([])
    }
  }, [loginError]) 



  useEffect(() => {
    if(!!isAuth){
      navigate('/profile')
    }
  }, [isAuth])

  const checkEmail = (value) => {
    setName(value.trim());
    setErrorName('')
    if(!value.length) setErrorName("Email cannot be empty")
    if(!regEmail.test(value)) setErrorName("Enter correct email")
  }

  const checkPass = (value)  => {
    setPass(value.trim());
    // setErrorPass('');
    // checkPassword(value, setErrorPass)
  } 

  useEffect(() => {
    AOS.init();
  }, [])

  useEffect(() => {
    if(
      (name !== null && !errorName.length) &&
      (pass !== null && !errorPass.length)
    ){
      setAllowSubmit(true)
    }else{
      setAllowSubmit(false)
    }
  }, [name, errorName, pass, errorPass])

  return (
    <section className={styles.login}>
      <div className="container">
        <div className={styles.login__wrapper}>
          <div className={styles.login__left}>
            <h2 className={styles.login__title}>{t("Welcome back!")}</h2>
            <div className={styles.login__descr}>
              <div>{t("Don’t have an account yet?")}</div>
            <Link to="/register">{t("Register")}</Link>
            </div>
            <form action="#" className={styles.login__form}>
              <label className={styles.login__label}>
                <Input 
                  placeholder="email"
                  label="Email"
                  type="text"
                  required={true}
                  value={name}
                  onChange={checkEmail}
                  error={errorName}
                />
              </label>
              <label className={styles.login__label}>
                <Input 
                  placeholder='•••••••••••••••'
                  label="Password"
                  type="password"
                  required={true}
                  value={pass}
                  onChange={checkPass}
                  error={errorPass}
                />
              </label>
              <div className={styles.login__btns}>
                <button
                  className={cn(styles.login__button, !allowSubmit ? styles.login__button_disabled : '')}
                  type="button"
                  onClick={() => store.login(name, pass)}
                >
                  {t("Log in")}
                </button>
                <Link 
                  to={'/reset-password'}
                  className={styles.login__reset}
                >
                  {t("Reset password")}
                </Link>
              </div>
              {
                (errors && errors.length > 0) ? 
                <>
                  {
                    errors?.map((item, index) => {
                      return(
                        <div className={styles.login__error} key={index}>{item}</div>
                      )
                    })
                  }
                </>
                :
                null
              }
            </form>
          </div>
          <div className={styles.login__right} data-aos="fade-left" data-aos-duration="1000"></div>
        </div>
      </div>
    </section>
  )
}

export default Login;