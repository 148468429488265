import React, { useEffect, useState, useContext } from 'react'
import styles from './SignUp.module.scss'
import Input from '../../Components/Input/Input'
import '../../common.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link, useNavigate, useHistory } from 'react-router-dom';
import { Modal } from '../../Components/Modal/Modal';
import cn from 'classnames';
import { Context } from '../../index';
import { useObserver, observer } from "mobx-react-lite";
import Loader from '../../Components/Loader/Loader';
import checkPassword from '../../helpers/checkPassword';
import { regEmail } from '../../helpers/regexp';
import { useTranslation } from 'react-i18next';


const SignUp = () => {
  const { t } = useTranslation(); 
  const [name, setName] = useState(null);
  const [errorName, setErrorName] = useState('');
  const [email, setEmail] = useState(null);
  const [errorEmail, setErrorEmail] = useState('');
  const [pass, setPass] = useState(null);
  const [errorPass, setErrorPass] = useState('');
  const [passAgain, setPassAgain] = useState(null);
  const [errorPassAgain, serErrorPassAgain] = useState('');
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [serversError, setServersError] = useState([]);
  const [loader, setLoader] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false);

  const navigate = useNavigate();

  const {store} = useContext(Context);

  const {errorRegistraion, showPageCheckPost, isLoadSignUp } = useObserver(() => ({
    errorRegistraion: store.errorRegistraion,
    showPageCheckPost: store.showPageCheckPost,
    isLoadSignUp: store.isLoadSignUp
  }));


  useEffect(() => {
    if(isLoadSignUp){
      setLoader(true)
    }else{
      setLoader(false)
    }
  }, [isLoadSignUp])

  useEffect(() => {
    if(errorRegistraion.length){
      setServersError(errorRegistraion)
    }else{
      setServersError([])
    }
  }, [errorRegistraion]) 

  useEffect(() => { 
    if(showPageCheckPost === true){
      // navigate('/check-mail')
      setIsOpenModal(true)
    }
  }, [showPageCheckPost])


  const checkName = (value) => {
    setName(value)
    setErrorName('')
    if(!value.length) setErrorName("Name cannot be empty")
    if(value.length > 40) setErrorName("Name must be between 1 and 40 characters")
  }

  const checkEmail = (value) => {
    setEmail(value.trim());
    setErrorEmail('')
    if(!value.length) setErrorEmail("Email cannot be empty")
    if(!regEmail.test(value)) setErrorEmail("Enter correct email")
  }

  const checkPass = (value)  => {
    setPass(value.trim());
    setErrorPass('');
    checkPassword(value, setErrorPass);
  } 

  const checkPassAgain = (value) => {
    setPassAgain(value.trim())
    serErrorPassAgain('')
    if(value !== pass) serErrorPassAgain('Passwords must match')
  } 


  useEffect(() => {
    AOS.init();
  }, [])

  useEffect(() => {
    setIsOpenModal(false)
  }, [navigate])

  useEffect(() => {
    if(
      (name !== null && !errorName.length) &&
      (email !== null && !errorEmail.length) && 
      (pass !== null && !errorPass.length) && 
      (passAgain !== null && !errorPassAgain.length) && 
      (pass === passAgain)
    ){
      setAllowSubmit(true)
    }else{
      setAllowSubmit(false)
    }
  }, [name, errorName, email, errorEmail, pass, errorPass, passAgain, errorPassAgain])

  return (
    <section className={styles.signup}>
      {
        loader ? 
        <Loader /> 
        :
        null
      }
      <div className="container">
        <div className={styles.signup__wrapper}>
          <div className={styles.signup__left}>
            <h2 className={styles.signup__title}>
              {t("Create an account")}
            </h2>
            <div className={styles.signup__descr}>
              <div>{t("Already have an account?")}</div>
              <Link to={"/login"}>Log in</Link>
            </div>
            <div className={styles.signup__form}>
              <Input 
                placeholder="name"
                label="Name"
                value={name}
                onChange={checkName}
                error={errorName}
              />
              <Input 
                placeholder="email"
                label="Email"
                value={email}
                onChange={checkEmail}
                error={errorEmail}
              /> 
              <div className={styles.signup__form_wrapper}>
                <Input 
                  placeholder="•••••••••••••••"
                  label="Password"
                  type="password"
                  value={pass}
                  onChange={checkPass}
                  error={errorPass}
                /> 
                <Input 
                  placeholder="•••••••••••••••"
                  label="Password again"
                  type="password"
                  value={passAgain}
                  onChange={checkPassAgain}
                  error={errorPassAgain}
                /> 
              </div>
              <button 
                className={cn(styles.signup__button, !allowSubmit ? styles.signup__button_disable : '')} 
                onClick={() => store.signup(name, email, pass)}
              >
                Sign up
              </button>
              {
                (serversError && serversError.length) ? 
                <div className={styles.signup__errors}>
                  {
                    serversError.map((item, index) => {
                      return(
                        <div key={index} className={styles.signup__errors_error}>
                          {item}
                        </div>
                      )
                    })
                  }
                </div>
                :
                null
              }
            </div>
          </div>
          <div className={styles.signup__right} data-aos="fade-left" data-aos-duration="1000"></div>
        </div>
      </div>
      {
        isOpenModal &&
        <Modal 
          email={email}
        />
      }
    </section>
  )
}

export default SignUp;