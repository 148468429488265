import React from 'react'
import styles from './TypeCertificate.module.scss'
import Aside from '../../Components/Aside/Aside';
import Certificate from './components/Certificate/Certificate';

const TypeCertificate = ({selectedCategory, selectedGroup, productName, batch, laboratory, dateAnalysis, expDate, type}) => {
  return (
    <div className={styles.wrapper}>
      <Aside />
      <Certificate 
        selectedCategory={selectedCategory}
        productName={productName}
        batch={batch}
        laboratory={laboratory}
        dateAnalysis={dateAnalysis}
        expDate={expDate}
        type={type}
        selectedGroup={selectedGroup}
      />
    </div>
  )
}

export default TypeCertificate;