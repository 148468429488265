import React from 'react'
import styles from './Contact.module.scss'
import '../../../common.css'
import BackIcon from '../../../img/imageComponents/BackIcon'
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation(); 
  return (
    <section className={styles.contact}>
      <div className="container">
        <div className={styles.contact__wrapper}>
          <div className={styles.contact__left}>
            <h2 className={styles.contact__title}>{t("Not find an answer?")}</h2>
          </div>
          <div className={styles.contact__form}>
            <form className={styles.contact__form_form}>
              <label>
                <span>{t("Your email")}</span>
                <input placeholder={t("type here")} type="text" />
              </label>
              <label>
                <span>{t("Message")}</span>
                <textarea placeholder={t("type here")}></textarea>
              </label>
              <button>
                <BackIcon />
                <div>{t("Send")}</div>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact;