import React, { useState, useEffect } from "react";
import Back from "../../../../Components/Buttons/Back/Back";
import styles from "./Certificate.module.scss";
import Input from "../../../../Components/Input/Input";
import Select from "../../../../Components/Select/Select";
import DatePicker from "../../../../Components/DatePicker/DatePicker";
import EditIcon from "../../../../img/imageComponents/EditIcon";
import DownloadIcon from "../../../../img/imageComponents/DownloadIcon";
import WarningCircle from "../../../../img/imageComponents/WarningCircle";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"
import getRequest from "../../../../helpers/getRequest";
import postRequest from '../../../../helpers/postRequest';
import cn from "classnames";
import { API_URL } from "../../../../http";
import convertTime from "../../../../helpers/convertTime";
import { useTranslation } from "react-i18next";

// const dataDropdowns = [
//   {
//     id: 0, categoryName: "Cannabis", 
//     data: [
//       {id: 0, groupName: "CBDProdukte"}, 
//       {id: 1, groupName: "HanfhaltigeLebensmittel"}, 
//       {id: 2, groupName: "Medizinalcannabis"},
//       {id: 3, groupName: "CannabisfürdenFreizeitkonsum"},
//     ]
//   },
//   {
//     id: 1, categoryName: "Lebensmittel", 
//     data: [
//       {id: 0, groupName: "Alge"},
//       {id: 1, groupName: "AlkoholhaltigesLebensmittel"},
//       {id: 2, groupName: "Babynahrung"},
//       {id: 3, groupName: "EierundEierprodukte"},
//       {id: 4, groupName: "Fleisch&Fleischerzeugnisse"},
//       {id: 5, groupName: "Fisch&Meeresfrüchte"},
//       {id: 6, groupName: "Früchte"},
//       {id: 7, groupName: "Genussmittel"},
//       {id: 8, groupName: "Getränke"},
//       {id: 9, groupName: "Getreide&Getreideerzeugnisse"},
//       {id: 10, groupName: "Gewürze,Würzmittel&Aromen"},
//       {id: 11, groupName: "Kräuter"},
//       {id: 12, groupName: "Milch"},
//       {id: 13, groupName: "Nahrungsergänzungsmittel"},
//       {id: 14, groupName: "NatürlicherHonig"},
//       {id: 15, groupName: "Nüsse"},
//       {id: 16, groupName: "Obst&Gemüse"},
//       {id: 17, groupName: "ÖleundFette"},
//       {id: 18, groupName: "Pilze&Pilzerzeugnisse"},
//       {id: 19, groupName: "Süßungsmittel"},
//       {id: 20, groupName: "Süßwaren"},
//       {id: 21, groupName: "VegetarischeundveganeErzeugnisse"},
//     ]
//   },
//   {
//     id: 2, categoryName: "Kosmetik",
//     data: [
//       {id: 0, groupName: "Bartpflege"},
//       {id: 1, groupName: "HaarpflegeundStyling"},
//       {id: 2, groupName: "Hautpflege"},
//       {id: 3, groupName: "Makeup&Reinigung"},
//       {id: 4, groupName: "Nagelpflege"},
//       {id: 5, groupName: "Naturkosmetik"},
//       {id: 6, groupName: "Parfüm"},
//       {id: 7, groupName: "UV-Absorber"},
//       {id: 8, groupName: "Zahn-undMundpflege"},
//     ]
//   },
//   {
//     id: 3, categoryName: "Pharma",
//     data: [
//       {id: 0, groupName: "FreiverkäuflicheArzneimittel"},
//       {id: 1, groupName: "ApothekenpflichtigeArzneimittel"},
//       {id: 2, groupName: "VerschreibungspflichtigeArzneimittel"},
//     ]
//   },
//   {
//     id: 4, categoryName: "Chemie", 
//     data: [
//       {id: 0, groupName: "AnorganischeChemie"},
//       {id: 1, groupName: "OrganischeChemie"},
//     ]
//   }
// ]

const Certificate = ({type, setType, category, setCategory, group, setGroup, productName, setProductName,
  dateAnalysis, setDateAnalysis, batch, setBatch, laboratory, setLaboratory, expDate, setExpDate, selectedCategory, setSelectedCategory, selectedGroup, setSelectedGroup}) => {
  // const [type, setType] = useState('');
  // const [category, setCategory] = useState([]);
  // const [group, setGroup] = useState([]);
  // const [productName, setProductName] = useState('');
  // const [dateAnalysis, setDateAnalysis] = useState('');
  // const [batch, setBatch] = useState('');
  // const [laboratory, setLaboratory] = useState('');
  // const [expDate, setExpDate] = useState('');
  // const [selectedCategory, setSelectedCategory] = useState(null);
  // const [selectedGroup, setSelectedGroup] = useState(null);
  const [isAllowSubmit, setIsAllowSubmit] = useState(true);
  const [errorForm, setErrorForm] = useState('');
  const [certificateId, setCertificateId] = useState('');
  const { t } = useTranslation();



  const navigate = useNavigate();
  const sendCertificate = (e) => {
    e.preventDefault();
    const data = { 
      analysisCertificateType: type,
      productGroup: selectedGroup?.id,
      productName: productName,
      batchNumber: batch,
      laboratoryAddress: laboratory,
      analysisDate: convertTime(dateAnalysis),
      expirationDate: convertTime(expDate)
    }
    axios({
      method: 'post',
      url: `${API_URL}/certificate`,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      data
    })
    .then(data => {
      setErrorForm("");
      setCertificateId(data?.data?.id)
    })
    .catch(e => {
      console.log(e)
      switch(e?.response?.data?.statusCode){
        case 401: {
          return setErrorForm("Try to re login")
        }
        case 400: {
          return setErrorForm("Check the validity of the filled fields")
        }
        case 404: {
          return setErrorForm("Product group with given name doesn't exist")
        }
        default: {
          return setErrorForm("Something went wrong")
        }
      }
    })
  }


  // useEffect(() => {
  //   if(dropdownData && dropdownData.length){
  //     let categories = dropdownData.map((category, index) => {
  //       return {categoryName: category.categoryName, id: index};
  //     })
  //     setCategory(categories)
  //   }
  // }, [dropdownData])

  // useEffect(() => {
  //   if(selectedCategory){
  //     const {id} = selectedCategory;
  //     let groups = dropdownData.filter(category => category.id === id)
  //     setGroup(groups[0].data);
  //   }
  // }, [selectedCategory])

  useEffect(() => {
    getRequest('product-category')
    .then(req => {
      console.log(req)
      setCategory(req.data.productCategories)
    })
    .catch(e => {
      alert('Category is not find')
    })
  }, [])

  useEffect(() => {
    if(category.length && selectedCategory){
      getRequest(`product-group/${selectedCategory.id}`)
      .then(req => {
        setGroup([...req?.data?.productGroups])
      })
    }
  }, [category, selectedCategory])

  useEffect(() => {
    if(certificateId && certificateId?.length){
      navigate(`/certificates/${certificateId}`)
    }
  }, [certificateId])


  useEffect(() => {
    if(
      type.length &&
      selectedCategory !== null && 
      selectedGroup !== null && 
      productName.length &&
      dateAnalysis &&
      expDate &&
      batch.length &&
      laboratory.length
    ){
      setIsAllowSubmit(true)
    }else{
      setIsAllowSubmit(false)
    }
  }, [type, selectedCategory, selectedGroup, productName, dateAnalysis, expDate, batch, laboratory])

  return (
    <section className={styles.certificate}>
      <Back path={"/certificates"} />
      <h2 className={styles.certificate__title}>
        {t("Add New Certificate")}
      </h2>
      <div className={styles.certificate__descr}>
        {t("Please enter all information about your analysis certificate that is to be stored in the blockchain.")}
        <br />
        <br />
        {t("This information is public and can be viewed along with the analysis certificate document.")}
        <br />
        <br />
        {t("After you have provided all the information, the next step is to contact your lab with the order ID and ask them to email us the analysis document as a PDF.")}
      </div>
      <div className={styles.certificate__noty}>
        <WarningCircle />
        <div>{t("The specification of the order number is mandatory.")}</div>
      </div>
      <div className={styles.certificate__wrapper}>
        <div className={styles.certificate__left}>
          <div className={styles.certificate__label}>
            <Input
              placeholder="type here"
              label="Type of analysis certificate"
              value={type}
              onChange={setType}
            />
          </div>
          <div className={styles.certificate__label}>
            <Select 
              label={t("Product Category")}
              options={category} 
              value={selectedCategory}
              onChange={setSelectedCategory}
            />
          </div>
          <div className={styles.certificate__label}>
            <Select 
              label={t("Product Group")}
              options={group} 
              value={selectedGroup}
              onChange={setSelectedGroup}
              isGroup={true}
            />
          </div>
          <div className={styles.certificate__label}>
            <Input 
              placeholder="type here"
              label="Product Name" 
              value={productName}
              onChange={setProductName}
            />
          </div>
        </div>
        <div className={styles.certificate__right}>
           <div className={styles.certificate__label}>
            <DatePicker
              value={dateAnalysis} 
              datePickAction={setDateAnalysis} 
              placeholder="2023-01-01" 
              title="Date of Analysis"
            />
          </div>
          <div className={styles.certificate__label}>
            <Input 
              placeholder="type here" 
              label="Batch Number" 
              value={batch}
              onChange={setBatch}
            />
          </div>
          <div className={styles.certificate__label}>
            <Input
              placeholder="type here"
              label="Laboratory / Adresse of the Laboratory"
              value={laboratory}
              onChange={setLaboratory}
            />
          </div>
          {/* <div className={styles.certificate__label}>
            <div>
              <span className={styles.certificate__logo_span}>
                Upload the logo of the company
              </span>
              <div className={styles.certificate__logo_wrapper}>
                <div className={styles.certificate__logo_photo}>
                  <label className={styles.certificate__logo_label}>
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                    />
                    <svg
                      width="42"
                      height="40"
                      viewBox="0 0 42 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.8464 8.33337V31.6667"
                        stroke="#AEAEAE"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.68604 20H33.0067"
                        stroke="#AEAEAE"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className={styles.certificate__logo_title}>
                      Drop files here or click to upload
                    </div>
                  </label>
                  <div className={styles.certificate__logo_accept}>
                    Add image .jpg or .png, 3mb max
                  </div>
                </div>
                <button className={styles.certificate__logo_edit}>
                  <EditIcon />
                </button>
              </div>
            </div>
          </div> */}
          <div className={styles.certificate__label}>
            <DatePicker 
              value={expDate} 
              datePickAction={setExpDate} 
              placeholder="2023-01-01" 
              title="Expiration date"
            />
          </div>
        </div>
      </div>
      {/* <Link to={"/certificates/type"} className={styles.certificate__addCertificate}>
          <DownloadIcon color="#ffffff" />
          <div>Add Certificate</div>
      </Link> */}
      <div className={styles.certificate__footer}>
        <Link 
          className={cn(styles.certificate__addCertificate, !isAllowSubmit ? styles.certificate__addCertificate_disabled : '')}
          to={`/certificates/${'0d0asd9adc3kadm4u5'}`}
          // onClick={(e) => sendCertificate(e)}
        >
          <DownloadIcon color="#ffffff" />
          <div>{t("Add Certificate")}</div>
        </Link>
        {
          errorForm.length ? 
          <div className={styles.certificate__form_error}>{errorForm}</div>
          :
          null
        }
      </div>
    </section>
  );
};

export default Certificate;
