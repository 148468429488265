import React from 'react'
import styles from './AccountBalance.module.scss';
import Aside from '../../Components/Aside/Aside'
import Balance from './Components/Balance/Balance';

const AccountBalance = () => {
  return (
    <div className={styles.wrapper}>
      <Aside />
      <Balance />
    </div>
  )
}

export default AccountBalance;