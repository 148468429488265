import React from "react";
import styles from "./Imprint.module.scss";
import "../../common.css";
import { useTranslation } from 'react-i18next';


const Imprint = () => {
  const { t } = useTranslation(); 
  return (
    <section className={styles.imprint}>
      <div className="container">
        <div className={styles.imprint__title}>
          {t("Imprint")}
        </div>
        <div className={styles.imprint__text}>
          {t("CeBiol Blockchain Solutions GmbH ")}
          <br />
          {t("Mittelweg 144")}
          <br /> 
          {t("20148 Hamburg Germany")}
        </div>
        <div className={styles.imprint__text}>
          {t("Represented by: Managing Director: Christian Tonn Commercial")}
          <br /> 
          {t("Register: Amtsgericht Hamburg, HRB 173329")}
        </div>
        <div className={styles.imprint__text}>
        {t("Contact: Email: ")}
        <a className={styles.imprint__link} href="mailto:info@Lab-Authenticity.org">info@Lab-Authenticity.org</a></div>
        <div className={styles.imprint__text}>
          {t("VAT identification number: DE352210976 Responsible for content according to § 55 Abs. 2 RStV: Christian Tonn Mittelweg 144 20148 Hamburg Germany")}
        </div>
        <div className={styles.imprint__subtitle}>{t("Legal information:")}</div>
        <div className={styles.imprint__text}>
          {t("CeBiol Blockchain Solutions GmbH regularly checks and updates the information on its website. Despite all care, the data may have changed in the meantime. Therefore, no liability or guarantee can be assumed for the timeliness, accuracy, and completeness of the information provided. The same applies to all other websites referred to by hyperlink. CeBiol Blockchain Solutions GmbH is not responsible for the content of the websites that are accessed through such a link.")}
          <br />
          <br />
          {t("The content and structure of the CeBiol Blockchain Solutions GmbH website are protected by copyright. The duplication of information or data, especially the use of texts, parts of texts, or image material, requires the prior consent of CeBiol Blockchain Solutions GmbH.")}
          <br />
          <br />
          {t("All information, use of the CeBiol Blockchain Solutions GmbH website, as well as all actions, toleration, or omission in connection with the CeBiol Blockchain Solutions GmbH website are subject exclusively to German law. The place of performance and exclusive place of jurisdiction is Hamburg, provided that the user is a merchant, a legal entity under public law, or a special fund under public law.")}
        </div>
      </div>
    </section>
  );
};

export default Imprint;
