import { useEffect } from 'react';
import styles from './Banner.module.scss';
import '../../../../common.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import banner from '../../../../img/banner.svg'

const Banner = () => {
  const { t } = useTranslation(); 

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className={styles.banner}>
      <div className='container'>
        <div className={styles.banner__content}>
          <div className={styles.banner__percent}>
            <div className={styles.banner__percent_sub}>{t("More than")}</div>
            <div className={styles.banner__percent_digit}>30%</div>
          </div>
          <div className={styles.banner__descr}>
            {t("of all publicly available certificates of analysis in the field of CBD and cannabis are falsified or manipulated. In the case of food, the issue is known as")} <span>"{t("food fraud")}"</span>
          </div>
          <img className={styles.banner__image} src={banner} alt="banner" data-aos="fade-left" data-aos-duration="1000"/>
        </div>
      </div>
    </div>
  )
}

export default Banner;