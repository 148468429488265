import { useState, useEffect, useContext } from "react";
import styles from "./Header.module.scss";
import "../../common.css";
import Button from "../Button/Button";
import logo from "../../img/logo.svg";
import DropdownArrow from "../../img/imageComponents/DropdownArrow";
import logoMob from "../../img/logoMob.svg";
import logoMenu from "../../img/menuLogo.svg";
import logout from "../../img/logout.svg";
import UserIcon from "../../img/imageComponents/UserIcon";
import CertificatIcon from "../../img/imageComponents/CertificatIcon";
import WalletIcon from "../../img/imageComponents/WalletIcon";
import QuestionIcon from "../../img/imageComponents/QuestionIcon";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import en from "../../img/lang/en.svg";
import de from "../../img/lang/de.svg";
import es from "../../img/lang/es.svg";
import fr from "../../img/lang/fr.svg";
import check from "../../img/checkCircle.svg";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import { useObserver } from "mobx-react-lite";
import axios from "axios";
import { API_URL } from "../../http";

const setActive = ({ isActive }) => (isActive ? styles.header__activeLink : "");

function Header({ user, setUser, lang, setLang, userName }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [fullHeader, setFullHeader] = useState(false);
  const [isOpenLang, setIsOpenLang] = useState(false);
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setLang(language);
    setIsMenuOpen(false);
  };
  const { t } = useTranslation();

  const location = useLocation();
  const { store } = useContext(Context);

  const { isAuth, registred, showMainPage } = useObserver(() => ({
    isAuth: store.isAuth,
    registred: store.registred,
    showMainPage: store.showMainPage,
  }));

  useEffect(() => {
    if (isAuth == false && registred) {
      navigate("/");
    }
  }, [isAuth]);

  useEffect(() => {
    if (showMainPage) {
      navigate("/");
    }
  }, [showMainPage]);

  const renderLangIcon = (lang) => {
    switch (lang) {
      case "en":
        return en;
      case "de":
        return de;
      case "sp":
        return es;
      case "fr":
        return fr;
      default:
        return en;
    }
  };

  useEffect(() => {
    const path = location.pathname;
    if (
      path === "/" ||
      path === "/verification" ||
      path === "/certificate" ||
      path === "/login" ||
      path === "/register"
    ) {
      setFullHeader(false);
      return;
    }
    setFullHeader(true);
  }, [location]);

  useEffect(() => {
    document.body.style.overflow = `${isMenuOpen ? "hidden" : "auto"}`;
  }, [isMenuOpen]);

  useEffect(() => {
    setIsOpenLang(false);
  }, [lang]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${API_URL}/user/me`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        const { username } = response?.data;
      })
      .catch((e) => {
        console.log(e);
        // alert("Something went wrong");
      });
  }, []);

  return (
    <header
      className={`${styles.header} ${fullHeader ? styles.header__shadow : ""}`}
    >
      <div
        className={`${
          fullHeader
            ? "container-fluid flex align-c jcsb"
            : "container flex align-c jcsb"
        }`}
      >
        <Link to={"/"}>
          <img className={styles.header__logoMob} src={logoMob} alt="logo" />
        </Link>
        <div
          className={`${styles.header__menu} ${
            isMenuOpen ? styles.header__menu_active : ""
          }`}
        >
          <Link to={"/"}>
            <img className={styles.header__logo} src={logo} alt="logo" />
          </Link>
          <img className={styles.header__menu_logo} src={logoMenu} alt="menu" />
          {location.pathname === "/login" ? null : (
            <ul className={styles.header__list}>
              <li>
                <NavLink
                  to={"/verification"}
                  className={setActive}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {t("Verify Document")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/partners"}
                  className={setActive}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {t("Partners")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/about"}
                  className={setActive}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {t("About Us")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/contact-us"}
                  className={setActive}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {t("Contact Us")}
                </NavLink>
              </li>
            </ul>
          )}
          <div className={styles.header__right}>
            <div className={styles.header__switcher}>
              <div
                className={styles.header__lang}
                onClick={() => {
                  setIsOpenLang((prev) => !prev);
                }}
              >
                <img src={renderLangIcon(lang)} alt="lang" />
                <div className={styles.header__lang_text}>{lang}</div>
                <DropdownArrow rotate={isOpenLang ? "180deg" : "0deg"} />
              </div>
              <div
                className={`${styles.header__dropdown} ${
                  isOpenLang ? styles.header__dropdown_active : ""
                }`}
              >
                <div
                  className={`${styles.header__dropdown_element} ${
                    lang === "en" ? styles.header__dropdown_element_active : ""
                  }`}
                  onClick={() => {
                    changeLanguage("en");
                  }}
                >
                  <img src={en} alt="en" />
                  <div>EN</div>
                  <img src={check} alt="check" />
                </div>
                <div
                  className={`${styles.header__dropdown_element} ${
                    lang === "de" ? styles.header__dropdown_element_active : ""
                  }`}
                  onClick={() => changeLanguage("de")}
                >
                  <img src={de} alt="de" />
                  <div>GE</div>
                  <img src={check} alt="check" />
                </div>
                {/* <div className={
                    `${styles.header__dropdown_element} ${lang === 'sp' ? styles.header__dropdown_element_active : ''}`
                  }
                  onClick={() => changeLanguage('sp')}
                >
                  <img src={es} alt="sp" />
                  <div>SP</div>
                  <img src={check} alt="check" />
                </div>
                <div className={
                    `${styles.header__dropdown_element} ${lang === 'fr' ? styles.header__dropdown_element_active : ''}`
                  }
                  onClick={() => changeLanguage('fr')}
                >
                  <img src={fr} alt="fr" />
                  <div>FR</div>
                  <img src={check} alt="check" />
                </div> */}
              </div>
            </div>
            {!user ? (
              <div className={styles.header__btns}>
                <Link to={"/login"} onClick={() => setIsMenuOpen(false)}>
                  <Button
                    width="115px"
                    height="43px"
                    text="Login"
                    color="whiteBlue"
                  />
                </Link>
                <Link to={"/register"} onClick={() => setIsMenuOpen(false)}>
                  <Button
                    width="115px"
                    height="43px"
                    text="Sign up"
                    color="blueWhite"
                  />
                </Link>
              </div>
            ) : (
              <div className={styles.header__user}>
                <div
                  className={styles.header__user_main}
                  onClick={() => setIsDropdownOpen((prev) => !prev)}
                >
                  <div className={styles.header__user_picture}>
                    {<UserIcon color="#ffffff"></UserIcon>}
                  </div>
                  <div className={styles.header__user_name}>{userName}</div>
                  <div
                    className={`${styles.header__user_menu} ${
                      isDropdownOpen ? styles.header__user_menu_active : ""
                    }`}
                  >
                    <Link
                      to={"/profile"}
                      className={`${styles.header__user_item} ${
                        location.pathname === "/profile"
                          ? styles.header__user_item_active
                          : ""
                      }`}
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <UserIcon />
                      {t("My Profile")}
                    </Link>
                    <Link
                      to={"/certificates"}
                      className={styles.header__user_item}
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <CertificatIcon />
                      {t("Certificates of Analysis")}
                    </Link>
                    <Link
                      to={"/account-balance"}
                      className={styles.header__user_item}
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <WalletIcon />
                      {t("Account Balance")}
                    </Link>
                    <Link
                      to={"/faq"}
                      className={styles.header__user_item}
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <QuestionIcon />
                      FAQ
                    </Link>
                  </div>
                </div>
                <div
                  className={styles.header__user_logout}
                  // onClick={() => setUser(false)}
                  onClick={() => store.logout()}
                >
                  <img src={logout} alt="logout" />
                </div>
              </div>
            )}
          </div>
        </div>
        <button
          onClick={() => setIsMenuOpen((prev) => !prev)}
          className={`${styles.burger} ${isMenuOpen ? styles.opened : ""}`}
        >
          <svg width="100" height="100" viewBox="0 0 100 100">
            <path
              className={`${styles.line} ${styles.line1}`}
              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
            />
            <path
              className={`${styles.line} ${styles.line2}`}
              d="M 20,50 H 80"
            />
            <path
              className={`${styles.line} ${styles.line3}`}
              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
            />
          </svg>
        </button>
      </div>
    </header>
  );
}

export default Header;
