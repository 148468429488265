import React, { useEffect } from 'react'
import styles from './Page404.module.scss'
import image from '../../img/404.svg'
import Back from '../../Components/Buttons/Back/Back';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Page404 = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section className={styles.page}>
      <img src={image} alt="404" data-aos="zoom-in" data-aos-duration="1000"/>
      <div className={styles.page__title}>
        Error 404. Page not found
      </div>
      <Back path="/" img={false} classname="bgBlue"/>
    </section>
  )
}

export default Page404;