import React, {useEffect, useState} from 'react'
import styles from './Balance.module.scss'
import WarningTriangle from '../../../../img/imageComponents/WarningTriangle'
import Document from '../../../../img/imageComponents/Document'
import Package from '../Package/Package'
import Payment from '../Payment/Payment'
import paypalIcon from '../../../../img/paypal.svg';
import stripeIcon from '../../../../img/stripe.svg';
import cebioIcon from '../../../../img/cebio.svg';
import CheckoutIcon from '../../../../img/imageComponents/CheckoutIcon';
import { countPercent } from '../../../../helpers/countPercent';
import { InvoiceTable } from '../../../../Components/InvoiceTable/InvoiceTable';
import { Link } from 'react-router-dom'
import PaymentModal from '../PaymentModal/PaymentModal'
import Input from '../../../../Components/Input/Input';
import SuccessCoupon from '../SuccessCoupon/SuccessCoupon'
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import { API_URL } from '../../../../http'
import { useNavigate } from 'react-router-dom'

const packages = [
  {value: "1 Certificate", price: 10, id: 1},
  {value: "6 Certificates", price: 50, id: 2},
  {value: "10 Certificates", price: 85, id: 3}
]

const payment = [
  {img: paypalIcon, text: "Pay with Paypal", id: 1, value: "paypal"},
  {img: stripeIcon, text: "Pay with Stripe", id: 2, value: "stripe"},
  {img: cebioIcon, text: "Pay with CBSL Token", id: 3, value: "cbsl_token"}
]

const Balance = () => {
  const { t } = useTranslation(); 
  const [chosenPackage, setChosenPackage] = useState(null);
  const [chosenPayment, setChosenPayment] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [isSuccessCertificate, setIsSuccessCertificate] = useState(false);
  const [total, setTotal] = useState(0)
  const [paymentError, setPaymentError] = useState('');


  const checkout = () => {
    setPaymentError('');
    const data = { 
      paymentMethod: chosenPayment,
      optionId: chosenPackage.id
    }
    axios({
      method: 'post',
      url: `${API_URL}/invoice`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      data
    })
    .then(response => {
      console.log(response)
      if(response.data?.redirectLink){
        window.location.replace(response.data?.redirectLink);
      }
    })
    .catch(error => {
      setPaymentError('Payment service is not responce now, try again later')
    })
  }

  useEffect(() => {
    setTotalPrice(chosenPackage?.price + countPercent(chosenPackage?.price))
  }, [chosenPackage])

  useEffect(() => {
    axios({
      method: "get",
      url: `${API_URL}/user/me`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      setTotal(response.data.certificatesBalance)
    })
    .catch((e) => {
      console.log(e);
    });
  }, [])

  return (
    <section className={styles.balance}>
      <h2 className={styles.balance__title}>
      {t("Account balance")}
        </h2>
      <div className={styles.balance__current}>
        <div className={styles.balance__balance}>
          <WarningTriangle />
          <div>
          {t("Current Balance")}
        </div>
        </div>
        <div className={styles.balance__info}>
          <div className={styles.balance__stat}>
            <div className={styles.balance__stat_count}>{total}</div>
            <div className={styles.balance__stat_text}>
          {t("certificates available")}
              </div>
          </div>
          <Document
            color={'#E5EEF8'}
            width={"124px"}
            height={"124px"}
          />
        </div>
      </div>
      <div className={styles.balance__check}>
        <div className={styles.balance__check_left}>
          <div className={styles.balance__check_label}>
          {t("Choose the package:")}
            </div>
          <div className={styles.balance__check_wrapper}>
            {
              packages.map(item => {
                const {value, price, id} = item
                return(
                  <Package  
                    value={value}
                    price={price}
                    key={id}
                    id={id}
                    chosenPackage={chosenPackage}
                    setChosenPackage={setChosenPackage}
                  />
                )
              })
            }
          </div>
          <div className={styles.balance__coupon}>
            <Input 
              placeholder={t("type here")}
              label={t("I have a coupon")}
            />
            <button 
              className={styles.balance__coupon_button}
              onClick={() => setIsSuccessCertificate(true)}
            >
              {t("Apply")}
            </button>
          </div>
        </div>
        <div className={`${styles.balance__check_right} ${!chosenPackage ? styles.balance__check_right_blur : ''}`}>
          <div className={styles.balance__check_label}>
          {t("Billing:")}
            </div>
          <div className={styles.balance__check_check}>
            <div className={styles.balance__check_value}>
              {
                chosenPackage && chosenPackage.value ? 
                chosenPackage.value
                :
                <span>{t("Choose your package")}</span>
              }
            </div>
            <div className={styles.balance__check_price}>
              {
                chosenPackage && chosenPackage.price ? 
                "€ " + chosenPackage.price.toFixed(2)
                :
                ""
              }
            </div>
          </div>
          <div className={styles.balance__check_summary}>
            <div className={styles.balance__check_title}>
              {t("Summary:")}
            </div>
            <div className={styles.balance__check_sum}>
              {
                (chosenPackage && totalPrice)? 
                "€ " + totalPrice.toFixed(2)
                :
                "-"
              }
            </div>
          </div>
          <div className={styles.balance__check_summary}>
            <div className={styles.balance__check_vat}> 
            {t("Include 19% VAT")}
            </div>
            <div className={styles.balance__check_vat_value}>
              {
                chosenPackage && chosenPackage.price ? 
                "€ " + countPercent(chosenPackage.price).toFixed(2)
                :
                "-"
              }
            </div>
          </div>
          <div className={styles.balance__payment}>
            {
              payment?.map(item => {
                const {img, text, id, value} = item
                return(
                  <Payment 
                    img={img}
                    text={t(text)}
                    value={value}
                    key={id}
                    id={id}
                    setChosenPayment={setChosenPayment}
                  />
                )
              })
            }
          </div>
          {
            chosenPayment !== "cbsl_token" ? 
            <div 
              className={`${styles.balance__checkout} ${!chosenPayment ? styles.balance__checkout_blur : ''}`}
              onClick={() => checkout()}
            >
              <CheckoutIcon />
              <div>
                {t("Proceed to Checkout")}
              </div>
            </div>
            :
            <div 
              className={`${styles.balance__checkout} ${!chosenPayment ? styles.balance__checkout_blur : ''}`}
              onClick={() => setIsOpenPaymentModal(prev => !prev)}
            >
              <CheckoutIcon />
              <div>{t("Proceed to Checkout")}</div>
            </div>
          }
          {
            paymentError && paymentError.length ? 
            <div className={styles.balance__payment_error}>
              {paymentError}
            </div>
            :
            null
          }
        </div>
      </div>
      <div className={styles.invoice}>
        <div className={styles.invoice__title}>
          {t("Invoices")}
        </div>
        <InvoiceTable />
      </div>
      {
        isOpenPaymentModal &&
        <PaymentModal 
          closeAction={setIsOpenPaymentModal}
        />
      }
      {
        isSuccessCertificate && 
        <SuccessCoupon 
          closeAction={setIsSuccessCertificate}
        />
      }
    </section>
  )
}

export default Balance;