import React, { useEffect, useState } from "react";
import styles from "./InvoiceTable.module.scss";
import DownloadIcon from "../../img/imageComponents/DownloadIcon";
import Status from "../Status/Status";
import axios from "axios";
import { API_URL } from "../../http";
import { useTranslation } from 'react-i18next';

const fakeData = [
  {amount: "€36.57", status: "Draft", number: "3DFF2C98-DRAFT", date: "Oct 21, 11:57 AM", downloadLink: 'www.google.com', id: '1'},
  {amount: "€36.57", status: "Paid", number: "3DFF2C98-DRAFT", date: "Oct 21, 11:57 AM", downloadLink: 'www.google.com', id: '2'},
  {amount: "€36.57", status: "Unpaid", number: "3DFF2C98-DRAFT", date: "Oct 21, 11:57 AM", downloadLink: 'www.google.com', id: '3'},
  {amount: "€36.57", status: "Paid", number: "3DFF2C98-DRAFT", date: "Oct 21, 11:57 AM", downloadLink: 'www.google.com', id: '4'},
  {amount: "€36.57", status: "Paid", number: "3DFF2C98-DRAFT", date: "Oct 21, 11:57 AM", downloadLink: 'www.google.com', id: '5'},
  {amount: "€36.57", status: "Paid", number: "3DFF2C98-DRAFT", date: "Oct 21, 11:57 AM", downloadLink: 'www.google.com', id: '6'}
]

export const InvoiceTable = () => {
  const { t } = useTranslation(); 
  // const [fakeData, setFakeDate] = useState([]);
  const [invoice, setInvoice] = useState([]);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${API_URL}/invoice`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
        "Accept": "application/json"
      }
    })
    .then((response) => {
      setInvoice(response.data)
    })
  }, [])

  return (
    <div className={styles.invoices__table}>
      {
        invoice && invoice.length ? 
        <>
          <div className={styles.invoices__header}>
        <div
          className={`${styles.invoices__header_title} ${styles.invoices__amount}`}
        >
          {t("Amount")}
        </div>
        <div
          className={`${styles.invoices__header_title} ${styles.invoices__status}`}
        >
          {t("Status")}
        </div>
        <div
          className={`${styles.invoices__header_title} ${styles.invoices__number}`}
        >
          {t("Invoice Number")}
        </div>
        <div
          className={`${styles.invoices__header_title} ${styles.invoices__date}`}
        >
          {t("Date")}
        </div>
        <div
          className={`${styles.invoices__header_title} ${styles.invoices__action}`}
        >
          {t("Action")}
        </div>
      </div>
      <>
        {invoice?.map((item) => {
          const { totalPrice, status, id, date, invoiceLink } = item;
          return (
            <div className={styles.invoices__row} key={id}>
              <div
                className={`${styles.invoices__amount} ${styles.invoices__row_data}`}
              >
                {totalPrice}
              </div>
              <div
                className={`${styles.invoices__status} ${styles.invoices__row_data}`}
              >
                <Status text={status} />
              </div>
              <div
                className={`${styles.invoices__number} ${styles.invoices__row_data}`}
              >
                {id}
              </div>
              <div
                className={`${styles.invoices__date} ${styles.invoices__row_data}`}
              >
                {date}
              </div>
              <div
                className={`${styles.invoices__action} ${styles.invoices__row_data}`}
              >
                {/* <a href={invoiceLink}>
                  <DownloadIcon />
                  <div>
                  {t("Download")}
                  </div>
                </a> */}
              </div>
            </div>
          );
        })}
      </> 
      </>
        :
        <div className={styles.invoices__empty}>
          {t("Invoice data is empty")} 🙅‍♂️
        </div>
      }

    </div>
  );
};
