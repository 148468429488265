import React from 'react'
import styles from './NewsPage.module.scss'
import { useTranslation } from 'react-i18next';
import "../../common.css";
import about from '../../img/about2.png'

export const NewsPage = () => {
  const { t } = useTranslation(); 
  return (
    <section className={styles.news}>
      <div className="container">
        <div className={styles.news__title}>{t("News")}</div>
        <div className={styles.news__subtitle}>{t("First partnerships concluded")}</div>
        <div className={styles.news__descr}>
          {t("Lab-Authenticity.org will cover numerous industries and sectors as the world's first platform for the proof of authenticity of certificates of analysis (laboratory analyses). Initially focused on the CBD and cannabis sector, the platform now also includes the food sector and the pharmaceutical industry. As a result, we can form close partnerships in these areas with companies that want to prove the authenticity of their laboratory analyses to their customers or business partners. We have already concluded the first partnerships for this purpose.")}
        </div>
        <img style={{marginTop: '30px'}} src={about} alt="" />
      </div>
    </section>
  )
}
