import React, { useState, useContext, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'

import Verification from '../pages/Verification/Verification';
import MainPage from '../pages/MainPage/MainPage';
import Certificate from '../pages/Certificate/Certificate';
import MyProfile from '../pages/MyProfile/MyProfile';
import Certificates from '../pages/Certificates/Certificates';
import AddNewCertificate from '../pages/AddNewCertificate/AddNewCertificate';
import Faq from '../pages/FAQ/Faq';
import TransactionCanceled from '../pages/TransactionCanceled/TransactionCanceled';
import Thank from '../pages/Thank/Thank';
import AccountBalance from '../pages/AccountBalance/AccountBalance';
import ThankCheckout from '../pages/ThankCheckout/ThankCheckout';
import TypeCertificate from '../pages/TypeCertificate/TypeCertificate';
import Login from '../pages/Login/Login';
import SignUp from '../pages/SignUp/SignUp';
import Partners from '../pages/Partners/Partners';
import ContactUs from '../pages/ContactUs/ContactUs';
import Page404 from '../pages/Page404/Page404';
import About from '../pages/About/About';
import Reset from '../pages/ResetPass/ResetPass';
import ThankRegister from '../pages/ThankRegister/ThankRegister';
import SuccessRegistration from '../Components/SuccessRegistration/SuccessRegistration';
import CheckPost from '../Components/CheckPost/CheckPost';
import VerifyReset from '../Components/VerifyReset/VerifyReset';
import SuccessfullyRegistred from '../Components/SuccessfullyRegistred/SuccessfullyRegistred';
import Error from '../pages/Error/Error';
import Layout from '../Layout/Layout';
import { Context } from '../index';
import { useObserver } from 'mobx-react-lite';
import Imprint from '../pages/Imprint/Imprint';
import axios from 'axios';
import { API_URL } from '../http';
import { NewsPage } from '../pages/NewsPage/NewsPage';
import { NewsPage2 } from '../pages/NewsPage2/NewsPage2';
import { NewsPage3 } from '../pages/NewsPage3/NewsPage3';
import Privacy from '../pages/Privacy/Privacy';
import Terms from '../pages/Terms/Terms';

const App = () => {
  const [user, setUser] = useState(false);
  const [lang, setLang] = useState('en');
  const [userName, setUserName] = useState('');
  // const [userNameHeader, setUserNameHeader] = useState('John Gray');
  const {store} = useContext(Context);
  const navigate = useNavigate();


  const [type, setType] = useState('');
  const [category, setCategory] = useState([]);
  const [group, setGroup] = useState([]);
  const [productName, setProductName] = useState('');
  const [dateAnalysis, setDateAnalysis] = useState('');
  const [batch, setBatch] = useState('');
  const [laboratory, setLaboratory] = useState('');
  const [expDate, setExpDate] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  
  const { isAuth } = useObserver(() => ({
    isAuth: store.isAuth,
  }));


  useEffect(() => {
    if(!!isAuth){
      setUser(true)
    }
  }, [isAuth])

  useEffect(() => {
    if(isAuth === false){
      setUser(false);
      // navigate('/')
    }
  }, [isAuth])

  useEffect(() => {
    if(localStorage.getItem('token')){
      store.checkAuth()
    }
  }, []) 

  useEffect(() => {
    axios({
      method: "get",
      url: `${API_URL}/user/me`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then(response => {
      setUserName(response.data.username)
    })
  }, [])

  return (
    <>
      <Routes>
        <Route 
          path='/' 
          element={
            <Layout 
              user={user} 
              setUser={setUser}
              lang={lang} 
              setLang={setLang} 
              userName={userName}
            />
          }>
          <Route 
            index
            element={<MainPage/>}
          />
          <Route 
            path='/imprint'
            element={<Imprint/>}
          />
          <Route 
            path='/privacy'
            element={<Privacy/>}
          />
          <Route 
            path='/terms'
            element={<Terms/>}
          />
          <Route 
            path='/news/nws10104tw'
            element={<NewsPage/>}
          />
          <Route 
            path='/news/nws20104tw'
            element={<NewsPage2/>}
          />
          <Route 
            path='/news/nws30104tw'
            element={<NewsPage3/>}
          />
          <Route 
            path='/check-mail'
            element={<CheckPost />}
          />
          <Route 
            path="/verification" 
            element={<Verification />}
          />
          <Route 
            path="/verification/certificate/:id" 
            element={<Certificate lang={lang} user={user}/>}
          />
          <Route 
            path='/success-registration'
            element={<SuccessfullyRegistred />}
          />
          <Route 
            path='/error'
            element={<Error />}
          />
          <Route 
            path="partners" 
            element={<Partners />}
          />
           <Route 
            path="about" 
            element={<About />}
          />
          <Route 
            path="contact-us" 
            element={<ContactUs />}
          />
          <Route 
            path="login" 
            element={<Login />}
          />
          <Route 
            path="register" 
            element={<SignUp />}
          />
          <Route 
            path="404" 
            element={<Page404 />}
          />
          <Route 
            path="*" 
            element={<Page404 />}
          />
          <Route
            path="faq"
            element={<Faq />}
          />
          <Route
            path="canceled"
            element={<TransactionCanceled />}
          />
          <Route
            path="thank/:id"
            element={<Thank />}
          />
          <Route 
            path="thank-checkout" 
            element={<ThankCheckout />}
          />
          <Route
            path="reset-password"
            element={<Reset />}
          />
          <Route 
            path='thank-register'
            element={<ThankRegister />}
          />
          <Route 
            path='verifyuser/:id'
            element={<SuccessRegistration />}
          />
          <Route 
            path='auth/reset/:id'
            element={<VerifyReset />}
          />
          {
            user ? (
              <>
              <Route 
                path="certificate" 
                element={<Certificate lang={lang} user={user}/>}
              />
              <Route 
                path="profile" 
                element={<MyProfile userName={userName} setUserName={setUserName} />}
              />
              <Route
                path="certificates"
                element={<Certificates />}
              />
              <Route
                path="certificates/new"
                element={
                  <AddNewCertificate 
                    type={type}
                    setType={setType}
                    category={category}
                    setCategory={setCategory}
                    group={group}
                    setGroup={setGroup}
                    productName={productName}
                    setProductName={setProductName}
                    dateAnalysis={dateAnalysis}
                    setDateAnalysis={setDateAnalysis}
                    batch={batch}
                    setBatch={setBatch}
                    laboratory={laboratory}
                    setLaboratory={setLaboratory}
                    expDate={expDate}
                    setExpDate={setExpDate}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup}
                  />
                }
              />
              <Route 
                path="account-balance" 
                element={<AccountBalance />}
              />
              <Route 
                path="certificates/:id" 
                element={
                  <TypeCertificate 
                    selectedCategory={selectedCategory}
                    productName={productName}
                    batch={batch}
                    laboratory={laboratory}
                    dateAnalysis={dateAnalysis}
                    expDate={expDate}
                    type={type}
                    selectedGroup={selectedGroup}
                  />
                }
              />
            </>
            ) 
            : 
            null
          }
        </Route>
      </Routes>
    </>
  )
}


export default App;