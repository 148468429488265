import styles from './Footer.module.scss';
import '../../common.css';
import checkpoint from '../../img/checkpoint.svg';
import { useTranslation } from 'react-i18next';
import fb from '../../img/facebook.svg';
import inst from '../../img/inst.svg';
import twitter from '../../img/twitter.svg';
import linkedin from '../../img/linkedin.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation(); 
  return (
    <section className={styles.footer}>
      <div className='container'>
        <div className={styles.footer__wrapper}>
          <div className={styles.footer__left}>
            <img src={checkpoint} alt='checkpoint'/>
            <div className={styles.footer__address}>
              CeBiol Blockchain Solutions GmbH
              Peutestrasse 53C
              20539 Hamburg, Germany
            </div>
          </div>
          <div className={styles.footer__right}>
            <div className={styles.footer__links}>
              <span className={styles.footer__link}>{t("A Project of CeBioLabs")}</span>
              <span className={styles.footer__devider}>|</span>
              <Link to="/privacy">
                <span className={styles.footer__link}>{t("Privacy")}</span>
              </Link>
              <span className={styles.footer__devider}>|</span>
              <Link to="/imprint">
                <span className={styles.footer__link}>{t("Imprint")}</span>
              </Link>
              <span className={styles.footer__devider}>|</span>
              <Link to="/terms">
                <span className={styles.footer__link}>{t("Terms")}</span>
              </Link>
              <span className={styles.footer__devider}>|</span>
              <Link to={"/faq"} className={styles.footer__link}>FAQ</Link>
              <span className={styles.footer__devider}>|</span>
              <span className={styles.footer__link}>{t("Contact")}</span>
            </div>
          </div>
        </div>
        <div className={styles.footer__socials}>
          <a href='https://www.facebook.com/profile.php?id=100091390312445' target="_blank">
            <img className={styles.footer__social} src={fb} alt='fb'/>
          </a>
          {/* <img className={styles.footer__social} src={inst} alt='inst'/> */}
          <a href='https://twitter.com/LabAuthenticity' target="_blank">
            <img className={styles.footer__social} src={twitter} alt='twitter'/>
          </a>
          {/* <img className={styles.footer__social} src={linkedin} alt='linkedin'/> */}
        </div>
        <div className={styles.footer__wrapper}>
          <div className={styles.footer__copy}>© {t("Copyright 2023 CeBiol Blockchain Solutions GmbH")}</div>
          <div className={styles.footer__noty}>{t("Made with")} <span>❤️️</span> {t("in Hamburg, Germany")}</div>
        </div>
      </div>
    </section>
  )
}

export default Footer;