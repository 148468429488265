import React, { useEffect } from 'react'
import styles from './ThankCheckout.module.scss'
import '../../common.css'
import Back from '../../Components/Buttons/Back/Back';
import thank from '../../img/thank.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';

const ThankCheckout = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section className={styles.thank}>
      <div className="container">
        <Back path={"/"}/>
        <div className={styles.thank__wrapper}>
          <div className={styles.thank__left}>
            <div className={styles.thank__title}>Thank You!</div>
            <div className={styles.thank__descr}>
              10 Certificates were added to your account successfully!
            </div>
            <div className={styles.thank__number}>
              Invoice number: 3DFF2C98-002
            </div>
          </div>
          <div className={styles.thank__right}>
            <img data-aos="fade-up" data-aos-duration="1000" src={thank} alt="thank" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ThankCheckout;