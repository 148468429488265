import React from 'react'
import styles from './NewsPage.module.scss'
import { useTranslation } from 'react-i18next';
import "../../common.css";
import about from '../../img/about2.png'

export const NewsPage2 = () => {
  const { t } = useTranslation(); 
  return (
    <section className={styles.news}>
      <div className="container">
        <div className={styles.news__title}>{t("News")}</div>
        <div className={styles.news__subtitle}>{t("Closed beta test Completed")}</div>
        <div className={styles.news__descr}>
          {t("We have now completed the final internal and closed beta testing and only need to make a few minor adjustments to graphics and editorial content. All basic functions are already completed, and the platform is ready for users. The marketing campaign for Lab-Authenticity.org will start soon and will cover numerous channels. We will also work with numerous influencers and start large-scale advertising campaigns.")}
        </div>
        <img style={{marginTop: '30px'}} src={about} alt="" />
      </div>
    </section>
  )
}
