import React from 'react'
import styles from './Package.module.scss'

const Package = ({value, price, id, chosenPackage, setChosenPackage}) => {
  return (
    <label 
      className={`${styles.package} ${chosenPackage === id ? styles.package__active : ''}`} 
      onClick={() => setChosenPackage({value, price, id})}
    >
      <div className={styles.package__wrapper}>
        <input type="radio" name="certificate" />
        <span className={styles.package__checkmark}></span>
        <div className={styles.package__value}>{value}</div>
      </div>
      <div className={styles.package__price}>{price} EUR</div>
    </label>
  )
}

export default Package;